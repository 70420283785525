import * as React from 'react';
import {useEffect, useState} from "react";
import {GetAllFranchises, GetAllLeadPurchase, GetUser} from "../../axios/Axios";
import {
    EditModule,
    FilterModule,
    FormatModule, GroupRowsModule,
    InteractionModule,
    PageModule,
    ResponsiveLayoutModule, SortModule,
    Tabulator
} from "tabulator-tables";
import Box from "@mui/joy/Box";
import {CircularProgress} from "@mui/joy";
import FormLabel from "@mui/joy/FormLabel";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import Typography from "@mui/joy/Typography";
import {Collaborator} from "airtable/lib/collaborator";
import {Attachment} from "airtable/lib/attachment";
import {FieldSet} from "airtable/lib/field_set";
import Record from "airtable/lib/record";
import '../../tabulator-tables.css';

interface Location {
    label: string;
    hasPermission: boolean;
    hasPermissionLabel: string;
}

interface Franchise {
    location: string;
}

interface User {
    first_name: string;
    last_name: string;
    location_permissions: string[];
}

export interface FieldSetReadable {
    [key: string]: undefined | string | number | boolean | Collaborator | Array<Collaborator> | Array<string> | Array<Attachment>;
}

export declare type RecordsReadable<TFields extends FieldSet> = Array<Record<TFields>>;


function isAutocompleteDisabled(user: User) {
    return user.location_permissions.length === 1 && !user?.location_permissions.includes('franchise.location.all');
}

function getDefaultOption(user: User) {
    let selectedLocation = localStorage.getItem('location')
    if (selectedLocation != null) {
        return [{
            label: selectedLocation,
            hasPermission: true,
            hasPermissionLabel: 'Your Locations'
        }];
    }

    if (user.location_permissions.length === 1) {
        let location;
        if (user.location_permissions[0].split('.').length >= 2) {
            location = user.location_permissions[0].split('.')[2]
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ')
        }
        return [{
            label: location,
            hasPermission: true,
            hasPermissionLabel: 'Your Locations'
        }];
    } else {
        let locations: Location[] = []
        user.location_permissions.forEach((permission) => {
            if (permission.split('.').length >= 2) {
                locations.push({
                    label: permission.split('.')[2]
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                        .join(' '),
                    hasPermission: true,
                    hasPermissionLabel: 'Your Locations'
                })
            }
        })
        return locations
    }


}

export default function PurchaseLeadsTable() {
    const [options, setOptions] = React.useState<Location[]>([]);
    const [loadedData, setLoadedData] = React.useState(false);
    const [rows, setRows] = React.useState<RecordsReadable<FieldSetReadable>>();
    const [tabulatorRef, setTabulatorRef] = React.useState<Tabulator>();
    const [franchiseFilter, setFranchiseFilter] = React.useState<Location[]>([]);
    const [defaultOption, setDefaultOption] = useState<Location[]>([])
    const [user, setUser] = useState<User>({first_name: '', last_name: '', location_permissions: []});
    const [autoCompleteDisabled, setAutocompleteDisabled] = useState<boolean>(true)
    const [franchises, setFranchises] = useState<Franchise[]>([]);

    Tabulator.registerModule([FilterModule, PageModule, ResponsiveLayoutModule, FormatModule, InteractionModule, EditModule, GroupRowsModule, SortModule]);


    useEffect(() => {
        setDefaultOption(getDefaultOption(user))
        setAutocompleteDisabled(isAutocompleteDisabled(user));
    }, [user]);

    useEffect(() => {
        if (loadedData) {
            initalizeTabulator()
        }
    }, [loadedData]);


    useEffect(() => {

        if (tabulatorRef != null) {
            initalizeTabulator()
        }
    }, [rows])

    useEffect(() => {
        setLoadedData(false)

        const fetchData = async () => {

            const userResponse = await GetUser();
            setUser(userResponse.data);
            let location = localStorage.getItem('location')?.toLowerCase().replace(' ', '_')
            const leadsPurchaseResponse = await GetAllLeadPurchase([location]);
            setRows(leadsPurchaseResponse)

            const franchisesResponse = await GetAllFranchises();
            setFranchises(franchisesResponse.data);
            setLoadedData(true)
        };
        fetchData()
    }, [])

    useEffect(() => {
        if (!loadedData) {
            return;
        }
        let locations = franchiseFilter.map(item => item.label.toLowerCase().replace(' ', '_'))
        const fetchData = async () => {
            const leadsPurchaseResponse = await GetAllLeadPurchase(locations);
            setRows(leadsPurchaseResponse)

        };
        fetchData()

    }, [franchiseFilter]);


    function handleInputChange(event: any, value: Location[]) {
        setFranchiseFilter(value)
    }


    useEffect(() => {
        const updateFranchiseList = () => {
            let optionListNew: Location[] = [];
            franchises.push({location: 'All'})
            franchises.forEach((franchise) => {
                let permissionLabel = user?.location_permissions.includes('franchise.location.' + franchise.location.toLowerCase().replace(' ', '_'));
                if (user?.location_permissions.includes('franchise.location.all')) {
                    permissionLabel = true;
                }
                optionListNew.push({
                    label: franchise.location,
                    hasPermission: permissionLabel ?? false,
                    hasPermissionLabel: permissionLabel ? 'Your Locations' : 'Other Locations'
                });
            });
            setOptions(optionListNew)
        };

        if (franchises.length > 0 && user) {
            updateFranchiseList();
        }
    }, [franchises, user]);

    function initalizeTabulator() {
        const tabulatorInstance = new Tabulator("#tabulator-container", {
            data: rows,
            pagination: true,
            paginationSize: 12,
            layout: "fitColumns",
            debugInvalidOptions: true,
            initialSort: [
                {column: "active", dir: "desc"},
            ],
            rowFormatter: function (row) {
                var data = row.getData();
                if (data.active) {
                    row.getElement().style.backgroundColor = "#d4edda";
                }
            },
            groupHeader: function (value, count, data, group) {
                if (value === false) {
                    value = 'Not Active'
                } else {
                    value = 'Active'
                }
                return value;
            },
            groupBy: "active",
            placeholder: "No Purchased Leads Found!",
            columns: [
                {
                    title: "Active", field: "active",
                    formatter: function (cell, formatterParams, onRendered) {
                        const value = cell.getValue();
                        if (value === false) {
                            return '<span class="cross">&#10060;</span>';
                        } else {
                            return '<span class="tick">&#10004;</span>';
                        }
                    }
                },
                {title: "ID", field: "id"},
                {
                    title: "Date Started",
                    field: "date_started",
                    formatter: function (cell, formatterParams, onRendered) {
                        const value = cell.getValue();
                        const date = new Date(value);

                        return date.toLocaleString();
                    },
                },
                {title: "Service", field: "service"},
                {
                    title: "Franchise Location",
                    field: "franchise.location"
                },
                {title: "Price", field: "price"},
                {title: "Leads Purchased", field: "leads_purchased"},
                {title: "Leads Generated", field: "leads_generated"},
                {
                    title: "Paid", field: "paid", formatter: function (cell, formatterParams, onRendered) {
                        const value = cell.getValue();
                        if (value === false) {
                            return '<span class="cross">&#10060;</span>';
                        } else {
                            return '<span class="tick">&#10004;</span>';
                        }
                    }
                },
            ],
        });
        setTabulatorRef(tabulatorInstance)

    }

    return (
        <>
            {loadedData ?

                <React.Fragment>
                    <Box sx={{display: 'flex'}}>
                        <Box>
                            <FormLabel>Select Location</FormLabel>
                            <Autocomplete
                                multiple
                                onChange={handleInputChange}
                                autoHighlight
                                sx={{width: '300px'}}
                                isOptionEqualToValue={(option: Location, value: Location) => option.label === value.label}
                                options={options.sort((a, b) => -String(a.hasPermissionLabel).localeCompare(String(b.hasPermissionLabel)))}
                                groupBy={(option) => String(option.hasPermissionLabel)}
                                getOptionLabel={(option) => option.label}
                                getOptionDisabled={(option) =>
                                    option.hasPermission === false
                                }
                                disabled={autoCompleteDisabled}
                                defaultValue={defaultOption}
                                renderOption={(optionProps, option) => (
                                    <AutocompleteOption {...optionProps} >
                                        {option.hasPermission &&
                                            <Typography color="success">{option.label}</Typography>}
                                        {!option.hasPermission &&
                                            <Typography color="danger">{option.label}</Typography>}
                                    </AutocompleteOption>
                                )}
                                slotProps={{
                                    input: {
                                        autoComplete: 'new-password',
                                    },
                                }}></Autocomplete>
                        </Box>

                    </Box>

                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'center',
                    }}>

                        <div id="tabulator-container"></div>
                    </Box>
                </React.Fragment>
                :
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    minWidth: '40%',
                    justifyContent: 'center'
                }}>
                    <CircularProgress
                        size="lg"
                    />
                </Box>


            }
        </>
    )

}