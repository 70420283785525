import * as React from 'react';
import {useEffect, useState} from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, {listItemButtonClasses} from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {closeSidebar} from '../../utils';
import {useLocation, useNavigate} from "react-router-dom";
import {GetFranchiseByName, GetUser, LogoutUser} from "../../axios/Axios";
import {
    AddShoppingCartOutlined, AutorenewRounded, BadgeOutlined, CalendarMonthRounded, CloudCircle,
    ContactEmergencyRounded,
    FormatQuoteRounded, HelpCenterRounded, HelpOutlineSharp, LinkRounded,
    StickyNote2Rounded
} from "@mui/icons-material";
import {AxiosResponse} from "axios";
import {Chip} from "@mui/joy";
import ColorSchemeToggle from "../ColorSchemeToggle";

interface User {
    first_name: string;
    last_name: string;
}

interface Franchise {
    name: string;
    gutter_cleaning_credit: number;
    window_cleaning_credit: number;
    roof_cleaning_credit: number;
}

function Toggler({
                     defaultExpanded = false,
                     renderToggle,
                     children,
                 }: {
    defaultExpanded?: boolean;
    children: React.ReactNode;
    renderToggle: (params: {
        open: boolean;
        setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    }) => React.ReactNode;
}) {
    const [open, setOpen] = React.useState(defaultExpanded);
    return (
        <React.Fragment>
            {renderToggle({open, setOpen})}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                        overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>
        </React.Fragment>
    );
}

export default function SideNav() {
    const location = useLocation();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState<User>({first_name: '', last_name: ''});
    const [selectedFranchise, setFranchiseSelected] = React.useState<Franchise>();

    let selectedLocation = localStorage.getItem('location')

    useEffect(() => {
        const fetchData = async () => {
            if (selectedLocation != 'All') {
                let fetchedFranchise = await GetFranchiseByName(selectedLocation);
                if (fetchedFranchise != null) {
                    setFranchiseSelected(fetchedFranchise);
                }
            }

            GetUser().then((response) => {
                if (response.status === 200) {
                    setLoaded(true);
                    setUser(response.data)
                }
            }).catch((err) => {
                setLoaded(true);
            })
        };
        fetchData();
    }, [setLoaded]);

    function isCurrentLocation(locationCheck: String) {
        return location.pathname === locationCheck;
    }

    function logout() {
        LogoutUser().then((response: AxiosResponse<any>) => {
            navigate("/");
        })
    }

    function purchaseLeads() {
        navigate("/dashboard/purchase-leads");
    }


    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: {xs: 'fixed', md: 'sticky'},
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
                boxSizing: 'border-box'
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '220px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, width: '100%'}}>
                <Typography level="title-md">Sky High Window Cleaning - {selectedLocation}</Typography>
                <ColorSchemeToggle sx={{ml: 'auto'}}/>
            </Box>
            <Input size="sm" startDecorator={<SearchRoundedIcon/>} placeholder="Search"/>
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >
                    <ListItem>
                        <ListItemButton
                            selected={isCurrentLocation('/dashboard')}
                            component="a"
                            href="/dashboard">
                            <HomeRoundedIcon/>
                            <ListItemContent>
                                <Typography level="title-sm">Home</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem>
                        <ListItemButton
                            selected={isCurrentLocation('/dashboard/employee-panel')}
                            component="a"
                            href="/dashboard/employee-panel">
                            <CloudCircle/>
                            <ListItemContent>
                                <Typography level="title-sm">Employee Panel</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem>
                        <ListItemButton selected={isCurrentLocation('/dashboard/leads')}
                                        component="a"
                                        href="/dashboard/leads">
                            <ContactEmergencyRounded/>
                            <ListItemContent>
                                <Typography level="title-sm">Leads</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem nested>
                        <Toggler
                            defaultExpanded={true}
                            renderToggle={({open, setOpen}) => (
                                <ListItemButton onClick={() => setOpen(!open)}>
                                    <AssignmentRoundedIcon/>
                                    <ListItemContent>
                                        <Typography level="title-sm">Bookings</Typography>
                                    </ListItemContent>
                                    <KeyboardArrowDownIcon
                                        sx={[
                                            open
                                                ? {
                                                    transform: 'rotate(180deg)',
                                                }
                                                : {
                                                    transform: 'none',
                                                },
                                        ]}
                                    />
                                </ListItemButton>
                            )}
                        >
                            <List>
                                <ListItem>
                                    <ListItemButton selected={isCurrentLocation('/dashboard/bookings')}
                                                    component="a"
                                                    href="/dashboard/bookings">
                                        <StickyNote2Rounded/>
                                        <ListItemContent>
                                            <Typography level="title-sm">All Bookings</Typography>
                                        </ListItemContent>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton selected={isCurrentLocation('/dashboard/recurring-bookings')}
                                                    component="a"
                                                    href="/dashboard/recurring-bookings">
                                        <AutorenewRounded/>
                                        <ListItemContent>
                                            <Typography level="title-sm">Recurring Bookings</Typography>
                                        </ListItemContent>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Toggler>
                    </ListItem>


                    <ListItem>
                        <ListItemButton selected={isCurrentLocation('/dashboard/customers')}
                                        component="a"
                                        href="/dashboard/customers">
                            <PersonRoundedIcon/>
                            <ListItemContent>
                                <Typography level="title-sm">Customers</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            selected={isCurrentLocation('/dashboard/calendar')}
                            component="a"
                            href="/dashboard/calendar">
                            <CalendarMonthRounded/>
                            <ListItemContent>
                                <Typography level="title-sm">Job Calendar</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton
                            selected={isCurrentLocation('/dashboard/purchase-leads')}
                            component="a"
                            href="/dashboard/purchase-leads">
                            <AddShoppingCartOutlined/>
                            <ListItemContent>
                                <Typography level="title-sm">Purchase Leads</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem sx={{marginTop: '40px'}}>
                        <ListItemContent>
                            <Typography level="body-sm" fontWeight="lg">Coming Soon</Typography>
                        </ListItemContent>
                    </ListItem>

                    <ListItem>
                        <ListItemButton sx={{opacity: 0.7}} disabled
                                        selected={isCurrentLocation('/dashboard/routing')}
                                        component="a"
                                        href="/dashboard/routing">
                            <MapRoundedIcon/>
                            <ListItemContent>
                                <Typography disabled level="title-sm">Routing</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>

                    <ListItem>
                        <ListItemButton sx={{opacity: 0.7}} disabled
                                        selected={isCurrentLocation('/dashboard/employees')}
                                        component="a"
                                        href="/dashboard/employees">
                            <BadgeOutlined/>
                            <ListItemContent>
                                <Typography disabled level="title-sm">Employee Management</Typography>
                            </ListItemContent>
                        </ListItemButton>
                    </ListItem>
                </List>

                <List
                    size="sm"
                    sx={{
                        mt: 'auto',
                        flexGrow: 0,
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        '--List-gap': '8px',
                    }}
                >
                    {selectedFranchise != null &&
                        <ListItem sx={{m: 0, p: 0, width: '100%'}}>
                            <Box sx={{justifyContent: 'center', width: '100%', alignItems: 'center'}}>
                                <Box onClick={() => purchaseLeads()} sx={{
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <Typography sx={{whiteSpace: 'nowrap'}} level="title-sm">WC
                                        Ads:</Typography>
                                    {selectedFranchise.window_cleaning_credit === 0 ?
                                        <Chip sx={{width: '100%', overflow: 'none'}} color="danger">Off</Chip>
                                        :
                                        <Chip sx={{width: '100%', overflow: 'none'}}
                                              color="success">{selectedFranchise.window_cleaning_credit} lead
                                            credits</Chip>
                                    }
                                </Box>
                                <Box onClick={() => purchaseLeads()} sx={{
                                    whiteSpace: 'no-wrap',
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <Typography sx={{whiteSpace: 'nowrap'}} level="title-sm">GC
                                        Ads:</Typography>
                                    {selectedFranchise.gutter_cleaning_credit === 0 ?
                                        <Chip sx={{width: '100%', overflow: 'none'}} color="danger">Off</Chip>
                                        :
                                        <Chip sx={{width: '100%', overflow: 'none'}}
                                              color="success">{selectedFranchise.gutter_cleaning_credit} lead
                                            credits</Chip>
                                    }
                                </Box>
                                <Box onClick={() => purchaseLeads()} sx={{
                                    whiteSpace: 'no-wrap',
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    <Typography sx={{whiteSpace: 'nowrap'}} level="title-sm">RC
                                        Ads:</Typography>
                                    {selectedFranchise.roof_cleaning_credit === 0 ?
                                        <Chip sx={{width: '100%', overflow: 'none'}} color="danger">Off</Chip>
                                        :
                                        <Chip sx={{width: '100%', overflow: 'none'}}
                                              color="success">{selectedFranchise.roof_cleaning_credit} lead
                                            credits</Chip>
                                    }

                                </Box>
                            </Box>
                        </ListItem>
                    }
                    <ListItem>
                        <ListItemButton component="a"
                                        href="/select-location">
                            <SupportRoundedIcon/>
                            Change Location
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <Divider/>
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Avatar
                    variant="outlined"
                    size="sm"
                />
                <Box sx={{minWidth: 0, flex: 1}}>
                    <Typography level="title-sm">{user.first_name}</Typography>
                    <Typography level="body-xs">{user.last_name}</Typography>
                </Box>
                <IconButton size="sm" variant="plain" color="neutral">
                    <LogoutRoundedIcon onClick={() => logout()}/>
                </IconButton>
            </Box>
        </Sheet>
    );
}