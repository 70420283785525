import * as React from "react";
import DashboardRouter from "./DashboardRouter";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./account/Login";
import Logout from "./account/Logout";
import SelectLocation from "./account/SelectLocation";

export default function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/select-location" element={<SelectLocation/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/dashboard/*" element={<DashboardRouter/>}/>
                </Routes>
            </BrowserRouter>
        </>
    )
}