import axios from "axios";
import {VerifyLoggedIn} from "./Axios";

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default function isDev() {
    return development;
}

let backend = 'https://api.proactivenetwork.co/bookings/'

if (isDev()) {
    backend = 'http://127.0.0.1:8000/bookings/'
}

export const AxiosPublic = axios.create({
    baseURL: backend,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,

});

AxiosPublic.interceptors.response.use(
    function (initialResponse) {
        return initialResponse;
    },
    async function (error) {
        if (error.response && error.response.status === 401) {
            try {
                const response = await VerifyLoggedIn();
                if (response.status === 200) {
                    return axios.request(error.config);
                } else {
                    return Promise.reject("No Longer Logged In!");
                }
            } catch (err) {
                return Promise.reject("No Longer Logged In!");
            }
        } else {
            return Promise.reject(error);
        }
    }
);


export function GetAllServicePrices(tier, house_type) {
    let params = {};

    if (tier) {
        params.tier = tier;
    }

    if (house_type) {
        params.house_type = house_type;
    }

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: 'service-prices/',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            params: params,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function EmployeeUpdateBooking(notes, payment_type, booking_id) {
    let params = {};


    if (payment_type) {
        params.payment_type = payment_type.name;
    }

    if (notes) {
        params.notes = notes;
    }

    if (booking_id) {
        params.booking_id = booking_id;
    }


    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'employee-update-booking/',
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            params: params,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetBookingsToday(locations) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: 'bookings-today/',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            params: params,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function GetAllBookings(locations, page, search, bookedIn = null) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }

    if (search) {
        params.search = search;
    }

    if (bookedIn) {
        params.booked_in = bookedIn;
    }

    params.page = page

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: 'bookings/',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            params: params,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function GetAllRecurringBookings(locations, page, search) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }

    if (search) {
        params.search = search;
    }

    params.page = page

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: 'recurring-bookings/',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            params: params,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function GetAllCustomers(locations, page, search) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }

    if (search) {
        params.search = search;
    }

    params.page = page


    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: 'customers/',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            params: params,
            withCredentials: true,

        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}


export function GetAllLeads(locations, page, search, filter = null, bookedIn = null) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }

    if (filter) {
        params.filter = filter;
    }

    if (search) {
        params.search = search;
    }

    if (bookedIn) {
        params.booked_in = bookedIn;
    }

    params.page = page


    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: 'leads/',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            params: params,
            withCredentials: true,

        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function GetBookingById(id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `bookings/${id}/`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function DeleteBookingById(id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `bookings/${id}/`,
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}


export function GetRecurringBookingById(id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `recurring-bookings/${id}/`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}


export function CreateBooking(bookingData, bookingValue, recurringBookings) {
    bookingData.services = [];
    bookingData.service_price = {};

    if (recurringBookings.length !== 0) {
        bookingData.recurring_booking = recurringBookings
    }

    if (bookingValue.windowClean) {
        bookingData.services.push('Window Clean');
    }
    if (bookingValue.gutterClean) {
        bookingData.services.push('Gutter Clean');
    }
    if (bookingValue.conservatoryGutterClean) {
        bookingData.services.push('Conservatory Gutter Clean');
    }

    if (bookingValue.conservatoryGutterCleanPrice !== 0) {
        bookingData.service_price['Conservatory Gutter Clean'] = bookingValue.conservatoryGutterCleanPrice;
    }
    if (bookingValue.windowCleanPrice !== 0) {
        bookingData.service_price['Window Clean'] = bookingValue.windowCleanPrice;
    }
    if (bookingValue.gutterCleanPrice !== 0) {
        bookingData.service_price['Gutter Clean'] = bookingValue.gutterCleanPrice;
    }
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'bookings/',
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: bookingData,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function UpdateBooking(id, bookingData, bookingValue, recurringBookings) {

    bookingData.recurring_booking = []
    bookingData.services = [];
    bookingData.service_price = {};

    if (recurringBookings.length !== 0) {
        bookingData.recurring_booking = recurringBookings
    }

    if (bookingValue.windowClean) {
        bookingData.services.push('Window Clean');
    }
    if (bookingValue.gutterClean) {
        bookingData.services.push('Gutter Clean');
    }
    if (bookingValue.conservatoryGutterClean) {
        bookingData.services.push('Conservatory Gutter Clean');
    }

    if (bookingValue.conservatoryGutterCleanPrice !== 0) {
        bookingData.service_price['Conservatory Gutter Clean'] = bookingValue.conservatoryGutterCleanPrice;
    }
    if (bookingValue.windowCleanPrice !== 0) {
        bookingData.service_price['Window Clean'] = bookingValue.windowCleanPrice;
    }
    if (bookingValue.gutterCleanPrice !== 0) {
        bookingData.service_price['Gutter Clean'] = bookingValue.gutterCleanPrice;
    }
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `bookings/${id}/`,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            data: bookingData,
            withCredentials: true,
        }).then((data) => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}


export function CreateRecurringBooking(recurringBookingData) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'recurring-bookings/',
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: recurringBookingData,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}


export function UpdateRecurringBooking(id, recurringBookingData) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `recurring-bookings/${id}/`,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            data: recurringBookingData,
            withCredentials: true,
        }).then((data) => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}


export function GetRecurringBookingByBookingId(bookingId) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `recurring-bookings-from-booking/${bookingId}/`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function DeleteCustomerById(id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `customers/${id}/`,
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}


export function GetCustomerById(id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `customers/${id}/`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function GetLeadById(id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `leads/${id}/`,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function DeleteLeadById(id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `leads/${id}/`,
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}


export function CreateLead(leadData, serviceValue) {

    leadData.services = [];
    leadData.service_price = {};

    if (serviceValue.windowClean) {
        leadData.services.push('Window Clean');
    }
    if (serviceValue.gutterClean) {
        leadData.services.push('Gutter Clean');
    }
    if (serviceValue.conservatoryGutterClean) {
        leadData.services.push('Conservatory Gutter Clean');
    }

    if (serviceValue.conservatoryGutterCleanPrice !== 0) {
        leadData.service_price['Conservatory Gutter Clean'] = serviceValue.conservatoryGutterCleanPrice;
    }
    if (serviceValue.windowCleanPrice !== 0) {
        leadData.service_price['Window Clean'] = serviceValue.windowCleanPrice;
    }
    if (serviceValue.gutterCleanPrice !== 0) {
        leadData.service_price['Gutter Clean'] = serviceValue.gutterCleanPrice;
    }
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'leads/',
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: leadData,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}

export function UpdateBookingTime(id, updatedDate) {
    let params = {}

    if (updatedDate) {
        params.date = updatedDate;
    }

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `bookings-date/${id}/`,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            data: params,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}


export function UpdateLead(id, leadData, serviceValue) {

    leadData.services = [];
    leadData.service_price = {};

    if (serviceValue.windowClean) {
        leadData.services.push('Window Clean');
    }
    if (serviceValue.gutterClean) {
        leadData.services.push('Gutter Clean');
    }
    if (serviceValue.conservatoryGutterClean) {
        leadData.services.push('Conservatory Gutter Clean');
    }

    if (serviceValue.conservatoryGutterCleanPrice !== 0) {
        leadData.service_price['Conservatory Gutter Clean'] = serviceValue.conservatoryGutterCleanPrice;
    }
    if (serviceValue.windowCleanPrice !== 0) {
        leadData.service_price['Window Clean'] = serviceValue.windowCleanPrice;
    }
    if (serviceValue.gutterCleanPrice !== 0) {
        leadData.service_price['Gutter Clean'] = serviceValue.gutterCleanPrice;
    }
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `leads/${id}/`,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            data: leadData,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}


export function UpdateCustomer(id, customerData) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + `customers/${id}/`,
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            data: customerData,
            withCredentials: true,
        }).then((data) => {
            resolve(data.data);
        }).catch(error => {
            reject(error);
        });
    });
}
