import * as React from 'react';
import {useEffect, useState} from 'react';
import Routing from "./dashboard-pages/Routing";
import Home from "./dashboard-pages/Home";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Leads from "./dashboard-pages/Leads";
import Bookings from "./dashboard-pages/Bookings";
import {GetUser, LogoutUser, VerifyLoggedIn} from "../axios/Axios";
import Customers from "./dashboard-pages/Customers";
import PurchaseLeads from "./dashboard-pages/Purchase-Leads";
import Box from "@mui/joy/Box";
import {CircularProgress} from "@mui/joy";
import Calendar from "./dashboard-pages/Calendar";
import RecurringBookings from "./dashboard-pages/Recurring-Bookings";
import EmployeePanel from "./dashboard-pages/Employee-Panel";

interface User {
    location_permissions: string[];
}

const PERMISSIONS = {
    ADMIN: 'admin',
    EMPLOYEE: 'employee',
};

export default function DashboardRouter() {
    let navigate = useNavigate()
    const [authenticated, setAuthenticated] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const [locationSet, setLocationSet] = useState(false);
    const [userPermissions, setUserPermissions] = useState<string[]>([]);

    useEffect(() => {
        const checkUserStatus = async () => {

            try {
                let localStorageLocationSet = localStorage.getItem('location') !== null;
                const response = await VerifyLoggedIn();
                if (response.status === 200) {
                    setAuthenticated(true);
                    const userResponse = await GetUser();
                    if (userResponse.status !== 200) {
                        return <Navigate to="/"/>;
                    }
                    if (localStorageLocationSet) {
                        let franchise = localStorage.getItem('location')
                        const permissions = userResponse.data.location_permissions;
                        setUserPermissions(permissions);

                        let permission = userResponse.data.location_permissions.includes('franchise.location.' + franchise?.toLowerCase().replace(' ', '_'))
                        if (userResponse.data.location_permissions.includes('franchise.location.all')) {
                            permission = true;
                        }
                        if (!permission) {
                            setLocationSet(false)
                        } else {
                            setLocationSet(true)
                        }
                    }
                }
            } catch (err) {
                await LogoutUser();
                navigate("/");
            } finally {
                setInitializing(false);
            }
        };

        checkUserStatus();
    }, []);


    if (initializing) {
        return <Box sx={{position: 'absolute', left: '50%', top: '50%'}}>
            <Box sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                flexWrap: 'wrap',
                height: '100%',
                width: '100%',
                justifyContent: 'center'
            }}>
                <CircularProgress
                    size="lg"
                />
            </Box>
        </Box>;
    }
    if (!locationSet) {
        localStorage.removeItem('location')
        return <Navigate to="/select-location"/>;
    }
    if (!authenticated) {
        return <Navigate to="/"/>;
    }

    const renderRoutes = () => {
        if (userPermissions.includes(PERMISSIONS.ADMIN)) {
            return (
                <>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/leads" element={<Leads/>}/>
                    <Route path="/customers" element={<Customers/>}/>
                    <Route path="/bookings" element={<Bookings/>}/>
                    <Route path="/recurring-bookings" element={<RecurringBookings/>}/>
                    <Route path="/purchase-leads" element={<PurchaseLeads/>}/>
                    <Route path="/calendar" element={<Calendar/>}/>
                    <Route path="/employee-panel" element={<EmployeePanel/>}/>
                </>
            );
        }

        if (userPermissions.includes(PERMISSIONS.EMPLOYEE)) {
            return (
                <>
                    <Route path="/" element={<EmployeePanel/>}/>
                    <Route path="/employee-panel" element={<EmployeePanel/>}/>
                </>
            );
        }
    };

    return (
        <Routes>
            {renderRoutes()}
        </Routes>
    );

}