import * as React from 'react';
import {Breadcrumbs, Chip, CircularProgress, CssBaseline, CssVarsProvider, Link, Select} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SideNav from "../dashboard-components/SideNav";
import Header from "../dashboard-components/Header";
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import FormLabel from "@mui/joy/FormLabel";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import {useEffect, useState} from "react";
import {GetAllFranchises, GetUser} from "../../axios/Axios";
import Option from '@mui/joy/Option';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import {GetAllQuotes, GetAllStatistics} from "../../axios/Airtable-Axios";
import {TrendingDown, TrendingFlat, TrendingUp} from "@mui/icons-material";
import {OverridableStringUnion} from "@mui/types";
import {ColorPaletteProp} from "@mui/joy/styles/types";
import {ChipPropsColorOverrides} from "@mui/joy/Chip/ChipProps";

interface LoadedStatistic {
    missed_work: number;
    leads_booked: number;
    total_bookings: number;
    total_generated_leads: number;
    total_leads_purchased: number;
    total_spent: number;
    outstanding_bookings: number;
    average_quote_price: number;
    addon_frequency_gc: number;
    addon_frequency_wc: number;
    addon_earnings: number;
    total_leads_wc: number;
    total_generated_leads_wc: number;
    total_outstanding_bookings_wc: number;
    total_spent_wc: number;
    total_due: number;
    leads_not_booked: number;
    price_of_leads_not_booked: number;
    quotes_today: number;
    bookings_today: number;
    quotes_this_week: number;
    bookings_this_week: number;
    quotes_this_month: number;
    bookings_this_month: number;
    quotes_this_year: number;
    bookings_this_year: number;
    earnings_today: number;
    earnings_this_week: number;
    earnings_this_month: number;
    earnings_this_year: number;
    earnings_yesterday: number;
    earnings_last_week: number;
    earnings_last_month: number;
    earnings_last_year: number;
    earnings_change_yesterday: number;
    earnings_change_last_week: number;
    earnings_change_last_month: number;
    earnings_change_last_year: number;
    quotes_yesterday: number;
    quotes_last_week: number;
    quotes_last_month: number;
    quotes_last_year: number;
    bookings_yesterday: number;
    bookings_last_week: number;
    bookings_last_month: number;
    bookings_last_year: number;
    quotes_change_yesterday: number;
    quotes_change_last_week: number;
    quotes_change_last_month: number;
    quotes_change_last_year: number;
    bookings_change_yesterday: number;
    bookings_change_last_week: number;
    bookings_change_last_month: number;
    bookings_change_last_year: number;
}

interface Location {
    label: string;
    hasPermission: boolean;
    hasPermissionLabel: string;
}

interface User {
    first_name: string;
    last_name: string;
    location_permissions: string[];
}

function getDefaultOption(user: User) {
    if (user?.location_permissions.includes('franchise.location.all')) {
        return [{
            label: 'All',
            hasPermission: true,
            hasPermissionLabel: 'Your Locations'
        }];
    } else {
        if (user.location_permissions.length === 1) {
            let location;
            if (user.location_permissions[0].split('.').length >= 2) {
                location = user.location_permissions[0].split('.')[2]
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join(' ')
            }
            return [{
                label: location,
                hasPermission: true,
                hasPermissionLabel: 'Your Locations'
            }];
        } else {
            let locations: Location[] = []
            user.location_permissions.forEach((permission) => {
                if (permission.split('.').length >= 2) {
                    locations.push({
                        label: permission.split('.')[2]
                            .split('_')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' '),
                        hasPermission: true,
                        hasPermissionLabel: 'Your Locations'
                    })
                }
            })
            return locations

        }
    }
}

function isAutocompleteDisabled(user: User) {
    return user.location_permissions.length === 1 && !user?.location_permissions.includes('franchise.location.all');
}

interface Franchise {
    location: string;
}

export default function Home() {
    const [statistics, setStatistics] = useState<LoadedStatistic>({} as LoadedStatistic);
    const [defaultOption, setDefaultOption] = useState<Location[]>([])
    const [autoCompleteDisabled, setAutocompleteDisabled] = useState<boolean>(true)
    const [franchiseFilter, setFranchiseFilter] = React.useState<Location[]>([]);
    const [options, setOptions] = React.useState<Location[]>([]);
    const [user, setUser] = useState<User>({first_name: '', last_name: '', location_permissions: []});
    const [loadedData, setLoadingData] = React.useState(false);
    const [initialLoad, setInitialLoad] = React.useState(false);
    const [validStats, setValidStats] = React.useState(true);

    const [franchises, setFranchises] = useState<Franchise[]>([]);


    useEffect(() => {
        setLoadingData(false)
        if (!initialLoad) return
        const fetchData = async () => {
            let locations = franchiseFilter.map(item => item.label.toLowerCase().replace(' ', '_'))
            const statisticsResponse = await GetAllStatistics(locations);
            if (statisticsResponse.data === 'No Quotes Found' || statisticsResponse.data === 'No Bookings Found' || statisticsResponse.data === 'No Locations Found') {
                setValidStats(false)
            } else {
                setValidStats(true)
            }

            setStatistics(statisticsResponse.data);
            setLoadingData(true)
        };
        fetchData()
    }, [franchiseFilter])

    useEffect(() => {
        setLoadingData(false)
        setInitialLoad(false)

        const fetchData = async () => {
            const userResponse = await GetUser();
            setUser(userResponse.data);
            let locations = userResponse.data.location_permissions;
            locations = locations.map(str => {
                const lastIndex = str.lastIndexOf('.');
                return str.substring(lastIndex + 1);
            });
            const franchisesResponse = await GetAllFranchises();
            setFranchises(franchisesResponse.data);

            const statisticsResponse = await GetAllStatistics(locations);
            if (statisticsResponse.data === 'No Quotes Found' || statisticsResponse.data === 'No Bookings Found' || statisticsResponse.data === 'No Locations Found') {
                setValidStats(false)
            }
            setStatistics(statisticsResponse.data);

            setLoadingData(true)
            setInitialLoad(true)

        };
        fetchData()
    }, []);
    useEffect(() => {

        const updateFranchiseList = () => {
            let optionListNew: Location[] = [];
            franchises.push({location: 'All'})
            franchises.forEach((franchise) => {
                let permissionLabel = user?.location_permissions.includes('franchise.location.' + franchise.location.toLowerCase().replace(' ', '_'));
                if (user?.location_permissions.includes('franchise.location.all')) {
                    permissionLabel = true;
                }
                optionListNew.push({
                    label: franchise.location,
                    hasPermission: permissionLabel ?? false,
                    hasPermissionLabel: permissionLabel ? 'Your Locations' : 'Other Locations'
                });
            });
            setOptions(optionListNew)
        };

        if (franchises.length > 0 && user) {
            updateFranchiseList();
        }
    }, [franchises, user]);


    useEffect(() => {
        setDefaultOption(getDefaultOption(user))
        setAutocompleteDisabled(isAutocompleteDisabled(user));
    }, [user]);

    function handleInputChange(event: any, value: Location[]) {
        setFranchiseFilter(value)
    }


    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline/>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Header/>
                <SideNav/>

                {!initialLoad ? (
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        width: '100%',
                        height: '100dvh',
                        justifyContent: 'center'
                    }}>
                        <CircularProgress
                            size="lg"
                        />
                    </Box>
                ) : (
                    <Box
                        component="main"
                        className="MainContent"
                        sx={{
                            px: {xs: 2, md: 6},
                            pt: {
                                xs: 'calc(12px + var(--Header-height))',
                                sm: 'calc(12px + var(--Header-height))',
                                md: 3,
                            },
                            pb: {xs: 2, sm: 2, md: 3},
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 0,
                            height: '100dvh',
                            gap: 1,
                        }}
                    >
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Breadcrumbs
                                size="sm"
                                aria-label="breadcrumbs"
                                separator={<ChevronRightRoundedIcon/>}
                                sx={{pl: 0}}
                            >
                                <Link
                                    underline="none"
                                    color="neutral"
                                    href="#some-link"
                                    aria-label="Home"
                                >
                                    <HomeRoundedIcon/>
                                </Link>
                                <Link
                                    underline="hover"
                                    color="neutral"
                                    href="#some-link"
                                    fontSize={12}
                                    fontWeight={500}
                                >
                                    Dashboard
                                </Link>
                                <Typography color="primary" fontWeight={500} fontSize={12}>
                                    Home
                                </Typography>
                            </Breadcrumbs>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                mb: 1,
                                gap: 1,
                                flexDirection: {xs: 'column', sm: 'row'},
                                flexWrap: 'wrap',
                                height: '100%'
                            }}
                        >
                            <Box sx={{width: '100%'}}>
                                <Typography level="h2" component="h1">
                                    Home
                                </Typography>
                                <Box sx={{display: 'flex', marginTop: '25px'}}>
                                    <Box sx={{marginRight: '10px'}}>
                                        <FormLabel> Select Location</FormLabel>
                                        <Autocomplete
                                            multiple
                                            onChange={handleInputChange}
                                            autoHighlight
                                            sx={{width: '250px', marginBottom: '25px'}}
                                            isOptionEqualToValue={(option: Location, value: Location) => option.label === value.label}
                                            options={options.sort((a, b) => -String(a.hasPermissionLabel).localeCompare(String(b.hasPermissionLabel)))}
                                            groupBy={(option) => String(option.hasPermissionLabel)}
                                            getOptionLabel={(option) => option.label}
                                            getOptionDisabled={(option) =>
                                                option.hasPermission === false
                                            }
                                            disabled={autoCompleteDisabled}
                                            defaultValue={defaultOption}
                                            renderOption={(optionProps, option) => (
                                                <AutocompleteOption {...optionProps} >
                                                    {option.hasPermission &&
                                                        <Typography color="success">{option.label}</Typography>}
                                                    {!option.hasPermission &&
                                                        <Typography color="danger">{option.label}</Typography>}
                                                </AutocompleteOption>
                                            )}
                                            slotProps={{
                                                input: {
                                                    autoComplete: 'new-password',
                                                },
                                            }}></Autocomplete>
                                    </Box>

                                </Box>

                                {!loadedData ? (
                                    <Box sx={{
                                        display: 'flex',
                                        gap: 2,
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                        height: '100dvh',
                                        justifyContent: 'center'
                                    }}>
                                        <CircularProgress
                                            size="lg"
                                        />
                                    </Box>
                                ) : (

                                    <Tabs orientation="horizontal"
                                          size="lg" sx={{width: '100%', height: '85%'}} defaultValue={0}>
                                        <TabList sx={{width: '100%', height: '50px'}} disableUnderline>
                                            <Tab variant="plain"
                                                 color="primary"
                                                 indicatorInset>Summary</Tab>
                                            <Tab disabled variant="plain"
                                                 color="primary"
                                                 indicatorInset>Lead Analysis</Tab>
                                            <Tab disabled variant="plain"
                                                 color="primary"
                                                 indicatorInset>Efficacy</Tab>
                                            <Tab disabled variant="plain"
                                                 color="primary"
                                                 indicatorInset>Due Or Missed</Tab>
                                            <Tab disabled variant="plain"
                                                 color="primary"
                                                 indicatorInset>Leads By Source</Tab>
                                        </TabList>

                                        <TabPanel value={0}>
                                            {!validStats ? (
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: 2,
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap',
                                                    width: '100%',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Typography color="danger" level="title-lg">No Statistics
                                                        Found!</Typography>
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <Typography level="title-sm">Conversions</Typography>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '50%',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <PercentageStatisticCard
                                                            title="Converted leads to bookings today"
                                                            firstValue={statistics.bookings_today}
                                                            secondValue={statistics.quotes_today}></PercentageStatisticCard>

                                                        <PercentageStatisticCard
                                                            title="Converted leads to bookings this week"
                                                            firstValue={statistics.bookings_this_week}
                                                            secondValue={statistics.quotes_this_week}></PercentageStatisticCard>

                                                        <PercentageStatisticCard
                                                            title="Converted leads to bookings this month"
                                                            firstValue={statistics.bookings_this_month}
                                                            secondValue={statistics.quotes_this_month}></PercentageStatisticCard>

                                                        <PercentageStatisticCard
                                                            title="Converted leads to bookings this year"
                                                            firstValue={statistics.bookings_this_year}
                                                            secondValue={statistics.quotes_this_year}></PercentageStatisticCard>
                                                    </Box>
                                                    <Typography level="title-sm">Leads / Quotes</Typography>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '50%',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <NumberStatisticCard title="Leads Today"
                                                                             value={statistics.quotes_today}
                                                                             from={'yesterday'}
                                                                             incrementPercentage={statistics.quotes_change_yesterday}
                                                                             incrementValue={statistics.quotes_yesterday}
                                                                             currency={false}></NumberStatisticCard>
                                                        <NumberStatisticCard title="Leads This Week"
                                                                             value={statistics.quotes_this_week}
                                                                             from={'last week'}
                                                                             incrementPercentage={statistics.quotes_change_last_week}
                                                                             incrementValue={statistics.quotes_last_week}

                                                                             currency={false}></NumberStatisticCard>
                                                        <NumberStatisticCard title="Leads This Month"
                                                                             value={statistics.quotes_this_month}
                                                                             from={'last month'}
                                                                             incrementPercentage={statistics.quotes_change_last_month}
                                                                             incrementValue={statistics.quotes_last_month}

                                                                             currency={false}></NumberStatisticCard>
                                                        <NumberStatisticCard title="Leads This Year"
                                                                             value={statistics.quotes_this_year}
                                                                             from={'last year'}
                                                                             incrementPercentage={statistics.quotes_change_last_year}
                                                                             incrementValue={statistics.quotes_last_year}
                                                                             currency={false}></NumberStatisticCard>
                                                    </Box>
                                                    <Typography level="title-sm">Bookings</Typography>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '50%',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <NumberStatisticCard title="Bookings Today"
                                                                             value={statistics.bookings_today}
                                                                             from={'yesterday'}
                                                                             incrementPercentage={statistics.bookings_change_yesterday}
                                                                             incrementValue={statistics.bookings_yesterday}
                                                                             currency={false}></NumberStatisticCard>
                                                        <NumberStatisticCard title="Bookings This Week"
                                                                             value={statistics.bookings_this_week}
                                                                             from={'last week'}
                                                                             incrementPercentage={statistics.bookings_change_last_week}
                                                                             incrementValue={statistics.bookings_last_week}
                                                                             currency={false}></NumberStatisticCard>
                                                        <NumberStatisticCard title="Bookings This Month"
                                                                             value={statistics.bookings_this_month}
                                                                             from={'last month'}
                                                                             incrementPercentage={statistics.bookings_change_last_month}
                                                                             incrementValue={statistics.bookings_last_month}
                                                                             currency={false}></NumberStatisticCard>
                                                        <NumberStatisticCard title="Bookings This Year"
                                                                             value={statistics.bookings_this_year}
                                                                             from={'last year'}
                                                                             incrementPercentage={statistics.bookings_change_last_year}
                                                                             incrementValue={statistics.bookings_last_year}
                                                                             currency={false}></NumberStatisticCard>
                                                    </Box>
                                                    <Typography level="title-sm">Earnings</Typography>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        width: '50%',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <NumberStatisticCard title="Earnings Today"
                                                                             value={statistics.earnings_today}
                                                                             from={'yesterday'}
                                                                             incrementPercentage={statistics.earnings_change_yesterday}
                                                                             incrementValue={statistics.earnings_yesterday}
                                                                             currency={true}></NumberStatisticCard>
                                                        <NumberStatisticCard title="Earnings This Week"
                                                                             value={statistics.earnings_this_week}
                                                                             from={'last week'}
                                                                             incrementPercentage={statistics.earnings_change_last_week}
                                                                             incrementValue={statistics.earnings_last_week}
                                                                             currency={true}></NumberStatisticCard>
                                                        <NumberStatisticCard title="Earnings This Month"
                                                                             value={statistics.earnings_this_month}
                                                                             from={'last month'}
                                                                             incrementPercentage={statistics.earnings_change_last_month}
                                                                             incrementValue={statistics.earnings_last_month}
                                                                             currency={true}></NumberStatisticCard>
                                                        <NumberStatisticCard title="Earnings This Year"
                                                                             value={statistics.earnings_this_year}
                                                                             from={'last year'}
                                                                             incrementPercentage={statistics.earnings_change_last_year}
                                                                             incrementValue={statistics.earnings_last_year}
                                                                             currency={true}></NumberStatisticCard>
                                                    </Box>
                                                </Box>
                                            )}
                                        </TabPanel>
                                        <TabPanel value={1}>
                                            <b>Second</b> tab panel
                                        </TabPanel>
                                        <TabPanel value={2}>
                                            <b>Third</b> tab panel
                                        </TabPanel>
                                    </Tabs>
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </CssVarsProvider>
    )

}

function NumberStatisticCard({title, value, incrementValue, incrementPercentage, from, currency}) {
    let increment = 'increase';
    let startDecorator = <TrendingUp/>;
    let color: OverridableStringUnion<ColorPaletteProp, ChipPropsColorOverrides> = 'success';
    const formattedValue = currency ? `£${Math.round(value)}` : value;
    const formattedIncrementedValue = currency ? `£${Math.round(incrementValue)}` : incrementValue;

    if (incrementPercentage < 0) {
        startDecorator = <TrendingDown/>
        increment = 'decrease'
        color = 'danger'
        incrementPercentage = Math.abs(incrementPercentage);

    }

    if (incrementPercentage === 0) {
        increment = 'no change'
        color = 'neutral'
        startDecorator = <TrendingFlat/>
    }


    return (
        <>
            <Card sx={{margin: 2, minWidth: '280px'}} color="primary" variant="outlined">
                <CardContent orientation="vertical">
                    <CardContent>
                        <Typography level="title-md">{title}</Typography>
                        <Typography level="title-lg">{formattedValue}</Typography>
                    </CardContent>
                    <Chip
                        startDecorator={startDecorator}
                        sx={{height: '35px'}}
                        color={color}
                        size="md"
                        variant="soft"
                    >{incrementPercentage}% {increment} from {from}</Chip>
                    <Chip
                        sx={{height: '35px'}}
                        color='neutral'
                        size="md"
                        variant="soft"
                    >{formattedIncrementedValue} {from}</Chip>
                </CardContent>
            </Card>
        </>
    )
}

function PercentageStatisticCard({title, firstValue, secondValue}) {
    const percentage = Math.round((firstValue / secondValue) * 100)

    return (
        <>
            <Card sx={{margin: 2, maxWidth: '280px', minWidth: '280px'}} variant="solid" color="primary" invertedColors>
                <CardContent orientation="horizontal">
                    <CircularProgress size="lg" determinate value={percentage}>
                        <Typography>{percentage}%</Typography>
                    </CircularProgress>
                    <CardContent>
                        <Typography level="body-md">{title}</Typography>
                        <Typography level="title-lg">{firstValue} / {secondValue}</Typography>
                    </CardContent>
                </CardContent>
            </Card>
        </>
    )
}