import axios from "axios";

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default function isDev() {
    return development;
}

let backend = 'https://api.proactivenetwork.co/'

if (isDev()) {
    backend = 'http://127.0.0.1:8000/'
}

export const AxiosPublic = axios.create({
    baseURL: backend,
    headers: {
        "Content-Type": "application/json",
    }
});

export const AxiosPrivate = axios.create({
    baseURL: backend,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

AxiosPrivate.interceptors.response.use(
    function (initialResponse) {
        return initialResponse;
    },
    async function (error) {
        if (error.response && error.response.status === 401) {
            try {
                const response = await VerifyLoggedIn();
                if (response.status === 200) {
                    return axios.request(error.config);
                } else {
                    return Promise.reject("No Longer Logged In!");
                }
            } catch (err) {
                return Promise.reject("No Longer Logged In!");
            }
        } else {
            return Promise.reject(error);
        }
    }
);


export function LoginUser(user) {
    return AxiosPrivate({
        url: backend + 'accounts/login/',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: user,
    })
}

export function LogoutUser() {
    return AxiosPublic({
        url: backend + 'accounts/logout/',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export function GetUser() {
    return AxiosPrivate({
        url: backend + 'accounts/user/',
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export function VerifyLoggedIn() {
    return AxiosPublic({
        url: backend + 'accounts/token/refresh/',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,

    })
}

export function GetAllFranchises() {
    return AxiosPrivate({
        url: backend + 'franchise/all/',
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    })
}

export function GetFranchiseByName(name) {
    name = name.replace(' ', '_')
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'franchise/location/' + name,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }

        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });

}


export function GetAllLeadPurchase(locations) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            locations: locationString
        };
    }
    return new Promise((resolve, reject) => {
        return AxiosPrivate({
            url: backend + 'leads/all-lead-purchases/',
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            params: params,

        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function SendRotaEmail(email, locations) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            locations: locationString
        };
    }
    return AxiosPrivate({
        url: backend + 'marketing/send-rota/' + email + '/',
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        params: params,

    })
}