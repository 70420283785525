import axios from "axios";

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default function isDev() {
    return development;
}

let backend = 'https://api.proactivenetwork.co/'

if (isDev()) {
    backend = 'http://127.0.0.1:8000/'
}

export const AxiosPublic = axios.create({
    baseURL: backend,
    headers: {
        "Content-Type": "application/json",
    }
});

export const AxiosPrivate = axios.create({
    baseURL: backend,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

export function GetAllStatistics(locations) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/all-statistics/',
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: params

        }).then((data) => {
            resolve(data)
        }).catch(error => {
            reject(error)
        });
    });

}

export function GetAllQuotes(locations) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/all-quotes/',
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: params

        }).then((data) => {
            resolve(data.data.records)
        }).catch(error => {
            reject(error)
        });
    });

}

export function GetAllCustomers(locations) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/all-customers/',
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: params

        }).then((data) => {
            resolve(data.data.records)
        }).catch(error => {
            reject(error)
        });
    });

}

export function GetAllQuoteUpdateLogs(quote_id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'logs/quote-update-log/' + quote_id,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,

        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });

}

export function PostQuoteUpdateLogs(date, user, status, quote_ids) {

    const data = {
        date: date,
        user: user,
        status: status,
        quotes: quote_ids
    };

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'logs/create-quote-update-log/',
            method: "POST",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            data: data
        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });

}

export function PostBookingUpdateLogs(date, user, status, booking_id) {

    const data = {
        date: date,
        user: user,
        status: status,
        booking: booking_id
    };

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'logs/create-booking-update-log/' + booking_id + '/',
            method: "POST",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            data: data
        }).then((data) => {
            resolve(data.data.fields)
        }).catch(error => {
            reject(error)
        });
    });

}


export function GetAllBookings(locations) {
    let params = {};

    if (locations && locations.length > 0) {
        const locationString = locations.join(',');
        params = {
            location: locationString
        };
    }
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/all-bookings/',
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: params

        }).then((data) => {
            resolve(data.data.records)
        }).catch(error => {
            reject(error)
        });
    });

}

export function GetAllBookingsByDate(location, date) {
    let params = {};


    params = {
        location: location,
        date: date
    };

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/all-bookings/',
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: params

        }).then((data) => {
            resolve(data.data.records)
        }).catch(error => {
            reject(error)
        });
    });

}


export function GetCustomer(id) {

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/get-customer/' + id,
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },

        }).then((data) => {
            resolve(data.data.fields)
        }).catch(error => {
            reject(error)
        });
    });

}

export function GetCustomersByPhone(phone_number, location) {

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/get-customers-by-phone/' + phone_number + '/' + location,
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },

        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });

}


export function GetQuote(id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/get-quote/' + id,
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },

        }).then((data) => {
            resolve(data.data.fields)
        }).catch(error => {
            reject(error)
        });
    });
}


export function GetFranchiseByName(name) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/get-franchise/' + name,
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },

        }).then((data) => {
            resolve(data.data.fields)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetServicePriceByHouseTypeAndTier(houseType, tier) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/get-service-price/' + houseType + '/' + tier,
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },

        }).then((data) => {
            resolve(data.data.fields)
        }).catch(error => {
            reject(error)
        });
    });
}


export function GetQuotesByCustomerId(customerId) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/get-quotes-by-customer/' + customerId,
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },

        }).then((data) => {
            resolve(data.data)
        }).catch(error => {
            reject(error)
        });
    });
}

export function GetBooking(id) {
    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/get-booking/' + id,
            method: "GET",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },

        }).then((data) => {
            resolve(data.data.fields)
        }).catch(error => {
            reject(error)
        });
    });
}

export function UpdateCustomer(id, name, email, houseType, phone) {

    const data = {
        name: name,
        email: email,
        house_type: houseType,
        phone: phone
    };

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/update-customer/' + id + '/',
            method: "POST",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            data: data
        }).then((data) => {
            resolve(data.data.fields)
        }).catch(error => {
            reject(error)
        });
    });

}

export function UpdateOrCreateBooking(id, booking) {

    const data = {
        date: booking.date,
        windowClean: booking.windowClean,
        gutterClean: booking.gutterClean,
        conservatoryGutterClean: booking.conservatoryGutterClean,
        conservatoryGutterCleanPrice: booking.conservatoryGutterCleanPrice,
        gutterCleanPrice: booking.gutterCleanPrice,
        windowCleanPrice: booking.windowCleanPrice,
        price: booking.price,


        customerId: booking.customerId,
        customerName: booking.customerName,
        email: booking.email,
        phone: booking.phone,
        address: booking.address,
        postcode: booking.postcode,
        houseTypeId: booking.houseTypeId,

        quoteId: booking.quoteId

    };

    return new Promise((resolve, reject) => {
        AxiosPublic({
            url: backend + 'airtable/update-booking/' + id + '/',
            method: "POST",
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            data: data
        }).then((data) => {
            resolve(data.data.fields)
        }).catch(error => {
            reject(error)
        });
    });

}